<template>
  <v-footer color="" >
    <v-row  no-gutters>
      <v-col class="d-flex justify-end">
      <v-btn
        v-for="(link, index) in links"
        :key="index"
        text
        rounded
        :href="link.href"
        color="grey"
        target="_blank"
        class="my-2"
      >
        {{ link.label }}
      </v-btn>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Footer",
  methods: {
    ...mapActions([
      "get_consultation_text_setting_privacy"
    ]),
    prepare_text_setting: function() {
      this.get_consultation_text_setting_privacy().then((response) =>{
        console.log(response.items);
        this.links = response
      })
    },
  },
  created(){
    this.prepare_text_setting()
  },
  data: () => ({
    links: [],
  }),
};
</script>
